import _ from 'lodash';
import { Component, Vue } from 'vue-property-decorator';
import App from '@/app';

@Component
export default class SelectLanguage extends Vue {
  visible: boolean = false;

  languages: LanguageItem[] = [{
    label: '繁中',
    value: 'tw',
  }, {
    label: '简中',
    value: 'cn',
  }, {
    label: 'English',
    value: 'en',
  }];

  get $app(): App | undefined {
    const app = _.first(this.$root.$children);
    if (!app) {
      return;
    }
    return app as App;
  }

  get currentLanguage(): LanguageItem | void {
    return _.find(this.languages, ['value', this.$i18n.locale]);
  }

  handleCurrentLanguageClick() {
    this.visible = !this.visible;
  }

  handleLanguageClick(lang: LanguageItem) {
    if (!this.$app) {
      return;
    }

    this.$app.locationService.load(lang.value);
    this.visible = false;
  }
}

interface LanguageItem {
  label: string;
  value: string;
}
