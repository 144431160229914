import { Vue, Component, Provide } from 'vue-property-decorator';
import NavigationBar from '@/views/BaseLayout/NavigationBar.vue';
import Footer from '@/views/BaseLayout/Footer.vue';
import { GroupItem } from '../Features/sidebar';
import { OgMeta } from '@/utils/seo/og-meta';
import { Meta } from '@/utils/seo/meta';

@Component({
  components: {
    NavigationBar,
    Footer,
  },
  head() {
    const desc = this.$t('base.description').toString();

    return {
      meta: [
        Meta.description(desc),
        OgMeta.description(desc),
        OgMeta.url(this.$route.path),
        ...OgMeta.image(
          // location.origin + require('@/assets/images/preview.jpg'),
          'https://images2.imgbox.com/c3/80/KuQEDTUI_o.jpg',
          800,
          800,
          'image/jpeg',
        ),
      ],
    };
  },
})
export default class BaseLayout extends Vue {
  sidebars: GroupItem[] = [];

  @Provide('baseLayout__setSidebarList')
  setSidebarList(data: GroupItem[]) {
    this.sidebars = data;
  }
}
