import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { RawLocation } from 'vue-router';

import { ROUTE_HOME_NAME, ROUTE_FEATURES_NAME, ROUTE_PRICING_NAME, ROUTE_FAQ } from '@/routes/root';
import { GroupItem } from '@/views/Features/sidebar';
import SelectLanguage from '@/components/SelectLanguage/SelectLanguage.vue';

@Component({
  components: {
    SelectLanguage,
  },
  created() {
    const self = this as NavigationBar;
    if (!self.$isServer) {
      window.addEventListener('scroll', self.handlePageScroll);
    }
  },
  destroyed() {
    const self = this as NavigationBar;
    window.removeEventListener('scroll', self.handlePageScroll);
  },
})
export default class NavigationBar extends Vue {
  $refs!: {
    navUseCase: HTMLDivElement;
  };

  @Prop({ type: Array, required: true })
  sidebars!: GroupItem[];

  visibleCollapse: boolean = false;

  isNavbarTransparent: boolean = true;

  isNavbarTop: boolean = true;

  get navs(): NavItem[] {
    const navs: NavItem[] = [{
      key: 'home',
      tkey: 'home.nav.home',
      location: { name: ROUTE_HOME_NAME },
    }, {
      key: 'pricing',
      tkey: 'home.nav.pricing',
      location: { name: ROUTE_PRICING_NAME },
    }]
    if (this.$i18n.locale === 'en') {
      navs.push({
        key: 'features',
        tkey: 'home.nav.features',
        location: { name: ROUTE_FEATURES_NAME },
      }, {
        key: 'faq',
        tkey: 'home.nav.faq',
        location: { name: ROUTE_FAQ },
      })
    } else {
      navs.push({
        key: 'features',
        tkey: 'home.nav.features',
        url: 'https://doc.moze.app/',
      }, {
        key: 'faq',
        tkey: 'home.nav.faq',
        url: 'https://faq.moze.app/',
      })
    }
    return navs
  }

  get isAtUseCasePage(): boolean {
    return this.$route.name === ROUTE_FEATURES_NAME;
  }

  get isAtHomePage(): boolean {
    return this.$route.name === ROUTE_HOME_NAME;
  }

  handleNavbarCollapseToggerClick() {
    this.visibleCollapse = !this.visibleCollapse;
  }

  handleUseCaseLinkClick() {
    if (this.visibleCollapse) {
      this.visibleCollapse = false;
    }
  }

  handleNavItemClick() {
    if (this.visibleCollapse) {
      this.visibleCollapse = false;
    }
  }

  handlePageScroll() {
    if (window.scrollY > 70) {
      this.isNavbarTop = false;
    } else {
      this.isNavbarTop = true;
    }
  }

  handleHomePageScroll() {
    if (window.scrollY > 70) {
      this.isNavbarTransparent = false;
    } else {
      this.isNavbarTransparent = true;
    }
  }

  @Watch('visibleCollapse')
  handleVisibleCollapseChange(val: boolean, oldVal: boolean) {
    if (val === true) {
      if (this.isAtUseCasePage === true && this.$refs.navUseCase) {
        const $target = this.$refs.navUseCase.querySelectorAll('.navbar__usecase-link.is-active')[0];
        if (!$target) { return; }
        $target.scrollIntoView();
      }
    }
  }

  @Watch('isAtHomePage', { immediate: true })
  handleIsAtHomePageChange(val: boolean, oldVal: boolean) {
    if (this.$isServer) { return; }

    if (val === true) {
      window.addEventListener('scroll', this.handleHomePageScroll);
    } else {
      window.removeEventListener('scroll', this.handleHomePageScroll);
    }
  }
}

export interface NavItem {
  key: string;
  tkey: string;
  url?: string;
  location?: RawLocation;
}
