import { render, staticRenderFns } from "./SelectLanguage.vue?vue&type=template&id=de853d66&scoped=true&"
import script from "./main.ts?vue&type=script&lang=ts&"
export * from "./main.ts?vue&type=script&lang=ts&"
import style0 from "./main.scss?vue&type=style&index=0&id=de853d66&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "de853d66",
  null
  
)

export default component.exports