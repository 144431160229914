import { Vue, Component } from 'vue-property-decorator';
import { ROUTE_FAQ, ROUTE_PRICING_NAME, ROUTE_PRIVACY, ROUTE_TERMS, ROUTE_FEATURES_NAME, ROUTE_WINNING_LIST } from '@/routes/root';
import { RawLocation } from 'vue-router';

@Component
export default class Footer extends Vue {
  get groups(): GroupItem[] {
    return [{
      name: this.$t('footer.product.name').toString(),
      links: [{
        label: this.$t('footer.product.items.feature').toString(),
        url: this.$i18n.locale !== 'en' ? 'https://doc.moze.app/' : undefined,
        to: this.$i18n.locale === 'en' ? { name: ROUTE_FEATURES_NAME } : undefined,
      }, {
        label: this.$t('footer.product.items.download').toString(),
        url: this.$i18n.locale === 'cn'
          ? 'https://apps.apple.com/cn/app/moze-3-0/id1460011387'
          : 'http://bit.ly/moze3',
      }, {
        label: this.$t('footer.product.items.pricing').toString(),
        to: { name: ROUTE_PRICING_NAME },
      }, {
        label: this.$t('footer.product.items.faq').toString(),
        url: this.$i18n.locale !== 'en' ? 'https://faq.moze.app/' : undefined,
        to: this.$i18n.locale === 'en' ? { name: ROUTE_FAQ } : undefined,
      }],
    }, {
      name: this.$t('footer.support.name').toString(),
      links: [{
        label: this.$t('footer.support.items.csv-import-example').toString(),
        url: 'http://bit.ly/moze_cht',
      }, this.$i18n.locale === 'tw' ? {
        label: this.$t('footer.support.items.winning-list').toString(),
        to: { name: ROUTE_WINNING_LIST },
      } : undefined, {
        label: this.$t('footer.support.items.press-kit').toString(),
        url: 'http://bit.ly/moze-presskit',
      }, {
        label: this.$t('footer.support.items.contactus').toString(),
        url: 'mailto:hello@moze.app',
      }],
    }, {
      name: this.$t('footer.social.name').toString(),
      links: [{
        label: this.$t('footer.social.items.facebook').toString(),
        url: 'http://bit.ly/moze_facebook',
      }, {
        label: this.$t('footer.social.items.twitter').toString(),
        url: 'http://bit.ly/moze_twitter',
      }, {
        label: this.$t('footer.social.items.telegram').toString(),
        url: 'http://bit.ly/moze_telegram',
      }, {
        label: this.$t('footer.social.items.weibo').toString(),
        url: 'http://bit.ly/moze_weibo',
      }],
    }, {
      name: this.$t('footer.legal.name').toString(),
      links: [{
        label: this.$t('footer.legal.items.privacy').toString(),
        to: { name: ROUTE_PRIVACY },
      }, {
        label: this.$t('footer.legal.items.terms').toString(),
        to: { name: ROUTE_TERMS },
      }],
    }];
  }
}

interface GroupItem {
  name: string;
  links: (LinkItem | undefined)[];
}

interface LinkItem {
  label: string;
  to?: RawLocation;
  url?: string;
}
